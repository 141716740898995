import { LOCALE_ID, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/**
 * Provides LOCALE_ID via a Service. This will mainly help to dynamically change the locale of pipes.
 * LocaleId should not be used directly in components. Use TranslateService.currentLang or LanguageService.currentLanguageCode instead.
 */
export class LocaleId extends String {
  constructor(private translateService: TranslateService) {
    super();
  }

  override toString(): string {
    return this.translateService.currentLang;
  }

  override valueOf(): string {
    return this.toString();
  }
}

/**
 * Provides [[LOCALE_ID]] via [[LocaleService]].
 */
export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [TranslateService],
};