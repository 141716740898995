import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { BaseComponent } from '../../../core/components/base.component';
import { LoaderService } from '../../../core/services/loader.service';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.scss'],
    standalone: true
})
export class LoaderComponent extends BaseComponent implements OnInit {
  show = false;
  block = false;

  constructor(
    private readonly loaderService: LoaderService,
    private readonly changeDetector: ChangeDetectorRef
  ) { 
    super(); 
  }

  ngOnInit(): void {
    this.loaderService.loaderState
      .unsubscribeOnDestroy(this)
      .subscribe((state: boolean) => {
        this.show = state;
        this.changeDetector.detectChanges();
      });

    this.loaderService.blockerState
      .unsubscribeOnDestroy(this)
      .subscribe((state: boolean) => {
        this.block = state;
        this.changeDetector.detectChanges();
      });
  }
}
