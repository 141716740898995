import { Component } from '@angular/core';
import { DarkModeService } from './core/services/dark-mode.service';
import { RouterOutlet } from '@angular/router';
import { LoaderComponent } from './shared/presentationals/loader/loader.component';
import { TranslateService } from '@ngx-translate/core';
import { DateAdapter } from '@angular/material/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [LoaderComponent, RouterOutlet]
})
export class AppComponent {
  constructor(readonly darkModeService: DarkModeService, readonly translateService: TranslateService, readonly dateAdapter: DateAdapter<any>) {
    // darkModeService.initialize();

    // Change language of date picker
    translateService.onLangChange.subscribe(() => { 
      dateAdapter.setLocale(translateService.currentLang);
    });
  }
}
