@if (show) {
  <div>
    <div class="loader-overlay">
      @if (show) {
        <div
        class="loader"></div>
      }
    </div>
  </div>
}
@if (block) {
  <div class="blocker-overlay"></div>
}
