import { Provider } from "@angular/core";
import { MAT_DATE_FORMATS, MatDateFormats } from "@angular/material/core";

const CUSTOM_DATE_FORMATS: MatDateFormats = {
    parse: {
      dateInput: 'DD.MM.YYYY',
    },
    display: {
      dateInput: 'DD.MM.YYYY',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };

export const MatDateFormatProvider: Provider = { 
    provide: MAT_DATE_FORMATS, 
    useValue: CUSTOM_DATE_FORMATS 
};