import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpEvent, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, Subject, throwError, switchMap } from 'rxjs';
import { ProblemDetails } from '../models/problem-details';
import { MessageService } from '../services/message.service';

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(
        private messageService: MessageService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: any) => {
                if (error.error instanceof ErrorEvent) {
                    this.messageService.openUnexpectedError();
                    return throwError(() => error.error);
                }
                else {
                    switch (error.status) {
                        case 401:
                            // Do not display an error message, since authentication interceptor will retry
                            break;
                        case 403:
                            // Do not display because user will be redirected to login
                            break;
                        case 400:
                        case 404:
                        case 405:
                        case 409:
                        case 500:
                            if (error.error?.detail || error.error?.title) {
                                const problemDetails = error.error as ProblemDetails;

                                this.showProblemDetailsErrorMessage(problemDetails);
                            } else if (error.error?.size) {
                                return this.parseErrorBlob(error).pipe(
                                    switchMap(parsedError => {
                                        if (typeof parsedError === 'string') {
                                            this.messageService.openUnexpectedError();
                                            return throwError(() => parsedError);
                                        }
                                        
                                        this.showProblemDetailsErrorMessage(parsedError);

                                        return throwError(() => parsedError.title);
                                    })
                                );
                            }
                            else if (error.error?.errors) {
                                this.messageService.openError(error.error.errors.join('\n'));
                            } else {
                                this.messageService.openUnexpectedError();
                            }
                            break;
                        default:
                            this.messageService.openUnexpectedError();
                            break;
                    }
                }

                return throwError(() => error);
            })
        );
    }

    private showProblemDetailsErrorMessage(problemDetails?: ProblemDetails): void {
        if ((problemDetails as any)?.errors?.length) {
            this.messageService.openError(Object.values((problemDetails as any).errors).join('\n'));
        }
        else if (problemDetails?.detail) {
            this.messageService.openError(problemDetails.detail);
        }
        else if (problemDetails?.title) {
            this.messageService.openError(problemDetails.title);
        }
        else {
            this.messageService.openUnexpectedError();
        }
    }

    private parseErrorBlob(errorResponse: HttpErrorResponse): Observable<ProblemDetails | string> {
        var subject = new Subject<ProblemDetails | string>();

        let reader = new FileReader();
        reader.onload = () => {
            let result = reader?.result as string;

            try {
                result = JSON.parse(result)
            }
            catch {
                // Response error is not a JSON object and might be a server error (HTML etc.)
            }

            subject.next(result);
        };
        reader.readAsText(errorResponse.error);

        return subject.asObservable();
    }
}