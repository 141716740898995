import rg4js from 'raygun4js';
import { ErrorHandler, Inject, Injectable } from '@angular/core';
import { AppConfigService } from '../services/app-config.service';
import { AuthenticationService } from '../services/authentication.service';
import { WINDOW } from "../providers/window.provider";

@Injectable()
// this class re-implements part of the AuthenticationService
// because AuthenticationService does not properly resolve in the contructor
export class GlobalErrorHandler implements ErrorHandler {
  private apiKey?: string;

  constructor(
    private readonly configService: AppConfigService,
    @Inject(WINDOW) private readonly window: Window
  ) {
  }

  handleError(error: any): void {
    console.log(error);

    this.checkAndSetupRaygun();

    if (this.apiKey) {
      this.setRaygunUserInfo();
      this.sendErrorToRaygun(error);
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(error?.message)) {
      this.window.location.reload();
    }

    // throw error;
  }

  private checkAndSetupRaygun(): void {
    if (!this.apiKey) {
      const config = this.configService.getConfig();

      if (config?.raygunApiKey) {
        this.apiKey = config.raygunApiKey;
        rg4js('apiKey', this.apiKey);
        rg4js('enableCrashReporting', true);
        rg4js('withTags', 'angular');
        rg4js('boot'); //force reload after configuration
      }
    }
  }

  private getUserInfo() {
    const item = AuthenticationService.getEmail(); // has to be static method as auth service is not available here

    return {
      identifier: (item ? item : 'n/a'),
      isAnonymous: (item === null)
    };
  }

  private setRaygunUserInfo() {
    rg4js('setUser', this.getUserInfo());
  }

  private sendErrorToRaygun(error: string): void {
    rg4js('send', {
      error: error,
    });
  }

}
