import { HttpClient, HttpBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IAppConfig } from '../models/app-config';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  static config: IAppConfig;
  private http: HttpClient;
  private readonly url = `assets/config/config.json`;

  constructor(handler: HttpBackend) {
    // Do not use any interceptor
    this.http = new HttpClient(handler);
  }

  init(): Observable<IAppConfig> {
    return this.http.get(this.url)
    .pipe(map(value => {
      AppConfigService.config = value as IAppConfig;

      return AppConfigService.config;
    }));
  }

  getConfig(): IAppConfig {
    return AppConfigService.config;
  }
}
