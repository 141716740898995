import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateLoader } from '@ngx-translate/core';
import { Injector } from '@angular/core';
import { TranslationApiService } from '../services/api-services';
import { environment } from 'src/environments/environment';

export class CustomTranslateHttpLoader extends TranslateLoader {
  constructor(private readonly injector: Injector) {
    super();
  }

  override getTranslation(lang: string): Observable<Object> {
    const translationApiService = this.injector.get(TranslationApiService);

    return translationApiService.getJson(lang, environment.version)
      .pipe(map(response => {
        return response as Object;
      }));
  }
}
