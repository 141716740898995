import { Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LanguageService } from '../services/language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private readonly languageService: LanguageService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const languageCode = this.languageService.currentLanguageCode;

    if (languageCode) {
      const headers = {};
      headers['Accept-Language'] = this.languageService.currentLanguageCode;

      request = request.clone({
        setHeaders: headers
      });
    }

    return next.handle(request);
  }
}
