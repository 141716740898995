import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslateMatPaginatorIntl extends MatPaginatorIntl {
    private readonly translationsParentKey = 'Paginator';
    private rangeLabelZero: string | null = null;
    private rangeLabel: string | null = null;

    constructor(private readonly translateService: TranslateService) {
        super();

        this.translateService.onLangChange.subscribe(() => {
            this.translate();
        });

        this.translate();

        this.getRangeLabel = (page: number, pageSize: number, length: number) => {
            if (length === 0 || pageSize === 0) {
                return this.rangeLabelZero?.replace('{{length}}', length.toString()) ?? '';
            }

            length = Math.max(length, 0);

            const startIndex = page * pageSize;

            // If the start index exceeds the list length, do not try and fix the end index to the end.
            const endIndex = startIndex < length ?
                Math.min(startIndex + pageSize, length) :
                startIndex + pageSize;

            if (this.rangeLabel) {
                return this.rangeLabel.replace('{{startIndex}}', (startIndex + 1).toString())
                    .replace('{{endIndex}}', endIndex.toString())
                    .replace('{{length}}', length.toString());
            }

            return '';
        };
    }

    translate() {
        this.translateService.get(this.translationsParentKey)
            .subscribe(translations => {
                this.itemsPerPageLabel = translations['ItemsPerPage'];
                this.nextPageLabel = translations['NextPageLabel'];
                this.previousPageLabel = translations['PreviousPageLabel'];
                this.firstPageLabel = translations['FirstPageLabel'];
                this.lastPageLabel = translations['LastPageLabel'];
                this.rangeLabelZero = translations['RangePageLabel1'];
                this.rangeLabel = translations['RangePageLabel2'];
                this.changes.next();
            });

    }
}