import { Routes } from '@angular/router';
import { ReloadComponent } from './core/components/reload.component';

export const appRoutes: Routes = [
  {
    path: 'reload',
    pathMatch: 'full',
    component: ReloadComponent
  },
  {
    path: '',
    loadChildren: () => import('./portal/portal.routes').then(m => m.portalRoutes)
  }
];