import { AuthenticationService } from '../services/authentication.service';
import { Observable, Subject, throwError, catchError, switchMap, take, finalize } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from '../services/message.service';
import { AuthenticationModel } from '../services/api-services';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private readonly refreshedToken$ = new Subject<string | null>();

    constructor(private readonly authenticationService: AuthenticationService,
        private readonly router: Router,
        private readonly messageService: MessageService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authenticationService.getAccessToken();

        if (token) {
            request = this.addToken(request, token);
        }

        // Handle response
        return next.handle(request).pipe(
            catchError(error => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    return this.handle401Error(request, next);
                }
                else {
                    return throwError(() => error);
                }
            }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshing) {
            this.isRefreshing = true;
            // Cancel previous pending requests
            this.refreshedToken$.next(null);

            return this.authenticationService.refreshToken().pipe(
                switchMap((result: AuthenticationModel | null) => {
                    this.refreshedToken$.next(result?.access_token ?? null);

                    if (result?.access_token) {
                        return next.handle(this.addToken(request, result?.access_token));
                    }
                    else {
                        const loginUrl = this.authenticationService.getLoginUrl(this.router.url);

                        return this.authenticationService.logout()
                            .pipe(switchMap(() => {
                                this.router.navigateByUrl(loginUrl);
                                this.messageService.translateOpenError('Messages.LoggedOutAfterInactivity');

                                // Return error and go into catchError to redirect
                                return throwError(() => new Error('No token received.'));
                            }));
                    }
                }),
                catchError(error => {
                    // Navigate to login page if refresh failed
                    this.router.navigate([this.authenticationService.getLoginUrl()]);

                    // Cancel pending requests
                    this.refreshedToken$.next(null);

                    return throwError(() => error);
                }),
                finalize(() => this.isRefreshing = false));
        }
        else {
            // Let other requests wait on the refreshed token
            return this.refreshedToken$.pipe(
                take(1),
                switchMap(token => {
                    if (token) {
                        return next.handle(this.addToken(request, token));
                    }

                    // Also "end" the request even when the token refresh failed
                    return throwError(() => new Error('No token received.'));
                }));
        }
    }

    private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`
            }
        });
    }
}

