import { APP_INITIALIZER, Injector, Provider } from "@angular/core";
import { AppConfigService } from "../services/app-config.service";
import { switchMap } from "rxjs";
import { LanguageService } from "../services/language.service";

export const AppInitializerProvider: Provider = { 
  provide: APP_INITIALIZER, 
  useFactory: (
    appConfigService: AppConfigService,
    injector: Injector
  ) => {
    // Initialize the AppConfig first because services depend on it.
    return () => appConfigService.init()
      .pipe(switchMap(() => injector.get(LanguageService).init()));
  }, 
  deps: [AppConfigService, Injector],
  multi: true 
};