import moment from "moment";
import DateTimeHelper from "../helpers/date-time.helper";

export namespace ISO8601DateTime {
    // Add the offset when serializing dates to JSON
    Date.prototype.toJSON = function() { 
        return moment(this).format(); 
    }

    moment.prototype.toJSON = function() { 
        return moment(this).format(); 
    }

    // Necessary because nswag files use toISOString to serialize dates in query parameters
    Date.prototype.toISOString = function() { 
        return moment(this).format();
    }

    let baseJsonParse = JSON.parse;

    JSON.parse = function(text: string) {
        const object = baseJsonParse(text);

        const parseDates = (object: any) => {
            for(let key in object) {
                if(typeof object[key] === 'object') {
                    parseDates(object[key]);
                }
                else if(DateTimeHelper.isDateString(object[key])) {
                    object[key] = moment(object[key]);
                }
            }
        }

        parseDates(object);

        return object;
    }
}